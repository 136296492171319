import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

// Layout components
import LoadingSpinner from './components/ui/LoadingSpinner';

// Lazy load page components for better performance
const Index = React.lazy(() => import('./pages/index'));
const Watch = React.lazy(() => import('./pages/watchtv'));
const News = React.lazy(() => import('./pages/news'));
const NewsArticlePage = React.lazy(() => import('./pages/newsarticle'));
const Shop = React.lazy(() => import('./pages/shop'));
const About = React.lazy(() => import('./pages/about'));
const Contact = React.lazy(() => import('./pages/contact'));
const NotFoundPage = React.lazy(() => import('./pages/notFound'));
const LiveVideoplayer = React.lazy(() => import('./components/account/livevideoplayer'));

// Initialize Google Analytics
ReactGA.initialize('G-7H1934RVJQ');

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Index />} />
            <Route path="/watch" element={<Watch />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:articleId" element={<NewsArticlePage />} />
            <Route path="/live/now-streamtv" element={<LiveVideoplayer />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <Analytics />
      </BrowserRouter>
    </div>
  );
}

export default App;